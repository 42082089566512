import "./style.css"
import review_pic_2 from "../../../../../assets/images/Dashboard/review_pic2.png";

export default function YelpRating(){
    return(
        <div className="dashboard_review_block2_cont_block2">
            <div>tgryhtju</div>
            {/*<img src={review_pic_2} alt=""/>*/}
        </div>
    )
}