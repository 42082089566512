import "./style.css"
import Modal from "react-modal";
import {useNavigate} from "react-router-dom";
import {useState} from "react";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        maxWidth:"921px",
        width:"100%",
    },
};


export default function AddLocationModal(props){
    let navigate=useNavigate()
    return(
        <div>
            <Modal
                isOpen={props.addLocationModalIsOpen}
                onRequestClose={props.closeAddLocationModal}
                style={customStyles}
                ariaHideApp={false}
            >
                <div className="add_location_modal">
                    <div className="">
                        <p className="f-700 fs-35 c-purple">Choose Location</p>
                        <div className="d-flex fd-column justify-content-center choose_location_cont">
                            {
                                props.location.map((item,index)=>{
                                    console.log(item.LocationName,"item.LocationName")
                                    return(
                                        <div className="choose_location_cont_item d-flex justify-content-space-between align-items-center" key={index}>
                                            <div className="">
                                                <h4 className="fs-22 f-700" >{item.LocationName}</h4>
                                                <p className="fs-18">{item.Address}</p>
                                            </div>
                                            <button className="add_location_btn c-white fs-16" onClick={()=>props.handleAddLocation(index)}>Add</button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}