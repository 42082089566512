import "./style.css"
import HeaderDashboard from "../../../components/Dashboard/header_dashboard/HeaderDashboard";
import location_img from "../../../assets/images/Dashboard/configuration-control-dashboard.png"
import {useNavigate} from "react-router-dom";
import ResponseProfileWithDates
    from "../DashboardReview/components/response_profiles/components/ResponseProfileWithDatas/ResponseProfileWithDatas";
import React, {useState} from "react";
import ForgetPasswordModal from "../../../components/forget-password-modal/ForgetPasswordModal";
import AddLocationModal from "../../../components/Dashboard/add-location-modal/AddLocationModal";

export default function Dashboard() {
    let navigate = useNavigate()
    let [location, setLocation] = useState([
        {
            id: 1,
            LocationName: "Uptown Dallas Location",
            Address: "2817 Maple Ave Floor 2, Dallas, TX 75201"
        },
        {
            id: 2,
            LocationName: "Oak Lawn Dallas Location",
            Address: "4617 Maple Ave, Dallas, TX 75219"
        },
        {
            id: 3,
            LocationName: "Greenville Dallas Location",
            Address: "3020 Greenville Ave, Dallas, TX 75206"
        },
        {
            id: 4,
            LocationName: "Uptown Dallas Location",
            Address: "2817 Maple Ave Floor 2, Dallas, TX 75201"
        },
        {
            id: 5,
            LocationName: "Oak Lawn Dallas Location",
            Address: "4617 Maple Ave, Dallas, TX 75219"
        },
    ])
    let [data, setData] = useState([
        {
            flag: false
        }
    ])
    const [addLocationModalIsOpen, setAddLocationModalIsOpen] = useState(false);

    function openAddLocationModal() {
        setAddLocationModalIsOpen(true);
    }

    function closeAddLocationModal() {
        setAddLocationModalIsOpen(false);
    }
    let handleAddLocation = () => {
        // data[index].flag = true
        data.push({flag: true})
        setData([...data])
        console.log("dtgdfgfgfgfgfgfgfgf")
    }

console.log(data, "data")
    let _renderLocationAddress = () => {
        return data.map((item, index) => {
            return (
                item.flag &&
                <div onClick={() => {
                    navigate("/dashboard/dashboard-review")
                }} style={{width: "100%", maxWidth: "365px"}} key={index}>
                    <button
                        className="location_button bc-purple dashboard_button justify-content-start align-items-center c-white fs-22 f-700 text-left"
                    >
                        <div className="line"></div>
                        <div className="d-flex fd-column">
                            <h4 className="fs-22 f-700 c-white">{item.LocationName}</h4>
                            <p className="c-white fs-16 f-500">{item.Address}</p>
                        </div>
                        <img src={location_img} alt="" className="location_img"/>
                    </button>
                </div>
            )
        })
    }

    return (
        <div className="main">
            <div>
                <HeaderDashboard isLogin={true}/>
                <div className="container">
                    <div className="container_inner">
                        <div className="dashboard_cont d-flex justify-content-space-between ">
                            <div className="d-grid grid-columns-4fr grid-gab_24 dashboard_cont_buttons">
                                {_renderLocationAddress()}
                                <button
                                    className="add_location_button bc-white dashboard_button justify-content-center align-items-center"
                                    onClick={openAddLocationModal}>
                                    <p className="c-purple fs-22 f-700 text-center d-flex justify-content-center align-items-center">Add
                                        Location <span className="c-purple fs-45 f-700">+</span></p>
                                </button>
                                <AddLocationModal
                                    addLocationModalIsOpen={addLocationModalIsOpen}
                                    setAddLocationModalIsOpen={setAddLocationModalIsOpen}
                                    openAddLocationModal={openAddLocationModal}
                                    closeAddLocationModal={closeAddLocationModal}
                                    location={location} setLocation={setLocation}
                                    handleAddLocation={handleAddLocation}/>
                            </div>
                            <div>

                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
