import {createStore} from "redux";

export let store = createStore(function (state,action){
    if(action.type==="change_name"){
        return{
            ...state,
            currentUser:{
                name:action.payload.name
            }
        }
    }
    return state;
},{
    currentUser:{
        // name:"Jack"
    }});

